
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import NotFound from '@Components/404Page/NotFound';
import Layout from '@Components/Layout';
import { generatePageSeo } from 'next-seo.config';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

const NotFoundPage = () => {
  const { t, lang } = useTranslation('common');
  const { asPath } = useRouter();
  return (
    <Layout
      seoData={generatePageSeo({
        title: t('yourparts-slogan'),
        asPath,
        lang,
      })}
    >
      <NotFound />
    </Layout>
  );
};

export default NotFoundPage;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  