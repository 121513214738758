import Link from '@Components/common/Link';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import Logo from 'public/logo.svg';
import { Actions, Div, ErrorCta, ErrorMessage, LogoWrapper } from './404Page.styled';
type Props = {};

export default function NotFound({}: Props) {
  const { t, lang } = useTranslation('common');
  return (
    <Div>
      <LogoWrapper>
        <Image src={Logo} width={400} height={100} objectFit="contain" alt="logo" />
      </LogoWrapper>
      <ErrorMessage>{t('error_message')}</ErrorMessage>
      <Actions>
        <ErrorCta variant="outlined" onClick={() => history.back()}>
          {t('get_back')}
        </ErrorCta>
        <ErrorCta variant="filled-red" as={Link} href="/">
          {t('get_home')}
        </ErrorCta>
      </Actions>
    </Div>
  );
}
